/* global config, Promise */

'use strict';

angular.module('managerApp').controller('BoxesController', (
        $scope, $location, $mdDialog, toast, boxes, procedureTools) => {

    $scope.config = config;

    const path = $location.path();
    $scope.firstRun = true;
    $scope.count = 0;
    $scope.items = [];
    $scope.newItem = {};
    $scope.pagination = {
        currentPage: 1,
        maxSize: 10,
        itemsPerPage: 25,
        totalItems: 0,
        pageChanged: () => {
            getBoxes();
        }
    };
    $scope.error = null;

    const getBoxes = () => {
        let params = {};
        if ($scope.pagination.filters) {
            for (let i in $scope.pagination.filters) {
                params[i] = $scope.pagination.filters[i];
            }
        }
        boxes.get(params, $scope.pagination.currentPage,
                $scope.pagination.itemsPerPage).then((data) => {
            if ($scope.firstRun)
                $scope.firstRun = false;
            $scope.count = data.count;
            $scope.items = data.items;
            $scope.pagination.totalItems = data.count;
            for (let i = 0; i < $scope.items.length; i++) {
                let item = $scope.items[i];
                if (item.PartnerId !== null && item.BoxPartner && item.BoxPartner.Client) {
                    let client = item.BoxPartner.Client;
                    client.personType = 'personne-physique';
                    item.partnerName = procedureTools.getPersonName(client);
                }
            }
            $scope.$apply();
        }).catch((err) => {
            $scope.error = err;
        });
    };

    $scope.toggleActive = (item) => {
        if (item.used) {
            return;
        }
        item.active = !item.active;
        boxes.update(item, 'application/json', item._id).then(() => {
            toast.show('Le box "' + item.code + '" a bien été mis à jour.', 'success');
        }).catch(() => {
            toast.show('Le box "' + item.code + '" n\'a pas pu être mis à jour.', 'error');
            item.active = !item.active;
        });
    };

    $scope.showAddEditPopup = () => {
        $mdDialog.show({
            templateUrl: 'app/boxes/item.html',
            parent: angular.element(document.body),
            clickOutsideToClose: false,
            locals: {
            },
            controller: ($scope, $mdDialog, boxes, clients) => {

                $scope.item = {
                    count: 1
                };

                $scope.getPartners = (name) => {
                    return boxes.get({
                        partners: 1,
                        name: name
                    }).then((partners) => {
                        return Promise.resolve(partners.items);
                    }).catch((err) => {
                        return Promise.reject(err);
                    });
                };

                $scope.closeDialog = (validated = false, form = null) => {
                    if (!validated) {
                        $mdDialog.cancel();
                    } else {
                        if (!form.$valid) { 
                            return;
                        }
                        if ($scope.item.partner) {
                            $scope.item.BoxPartnerId = $scope.item.partner._id;
                            delete $scope.item.partner;
                        }
                        $mdDialog.hide(boxes.generate($scope.item, true));
                    }//
                };
            }
        }).then((res) => {
            if (res) {
                toast.show('Les codes box ont bien été générés.', 'success');
                getBoxes();
            }
        }).catch((err) => {
            if (err) {
                toast.show(err && err.message ? err.message : config.defaultErrorMsg, 'error', true);
            }
        });
    };

    $scope.showDeleteConfirm = ($event, item) => {
        $mdDialog.show({
            parent: angular.element(document.body),
            targetEvent: $event,
            template:
                    `<md-dialog layout-padding flex="35" aria-label="List dialog">
                    <md-dialog-content layout="row" layout-wrap>
                        <div flex='100'>
                            <h3 style='margin: 0; margin-bottom: 10px;'>
                                <md-icon style='font-size: 24px; color: red'>warning</md-icon> Suppression
                            </h3>
                            <md-divider></md-divider><br>
                        </div>
                        <div>
                            <p>Voulez-vous vraiment supprimer le box "<b>` + item.code + `</b>" ?</p>
                            <p>Cette opération sera exécutée immédiatement et elle est <b>irréversible</b> !</p>
                        </div>
                    </md-dialog-content>
                    <md-dialog-actions>
                        <div class='buttons-container'>
                            <md-button ng-click="closeDialog()"
                                class="md-button green btn">Annuler</md-button>
                            <md-button ng-click="closeDialog(true)" 
                                class="btn btn-submit red">Confirmer la suppression</md-button>
                        </div>
                    </md-dialog-actions>
                  </md-dialog>`,
            locals: {
                itemId: item._id
            },
            controller: ($scope, $mdDialog, toast, boxes, itemId) => {
                $scope.closeDialog = (confirm = false) => {
                    $mdDialog.hide();
                    if (confirm) {
                        boxes.delete(itemId).then(() => {
                            toast.show('Le box a bien été supprimé.', 'success');
                            getBoxes();
                        }).catch((err) => {
                            toast.show('Une erreur est survenu lors de la suppression' +
                                    ' de ce box.');
                        });
                    }//
                };
            }
        });
    };

    getBoxes();

});
